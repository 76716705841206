import React from 'react'
import { useState, useEffect } from 'react';
import './BottomNavigation.css';
import "./../Public/fonts/font.css"
// import HomeIcon from '@mui/icons/Home';
// import HomeOutlined from '@mui/icons-material/HomeOutlined';
// import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Receipt from '../Public/Image/bottomnav/receipt.svg'
import Receiptfilled from '../Public/Image/bottomnav/receiptfilled.svg'
import CoinActive from '../Public/Image/bottomnav/CoinActive.svg'
import Coin from '../Public/Image/bottomnav/CoinNavDeactive.svg'
import SaleIcon from '../Public/Image/bottomnav/SaleIcon.svg'
import SaleIconActive from '../Public/Image/bottomnav/SaleIconActive.svg'


import GameIcon from '../Public/Image/bottomnav/Game.svg'
import GameActiveIcon from '../Public/Image/bottomnav/GameActive.svg'


import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Home from './../Public/Image/bottomnav/Home.svg'
import HomeDisable from './../Public/Image/bottomnav/HomeDeactive.svg'
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import { useSelector, useDispatch } from 'react-redux'
import { SetEmail } from './../app/Slice'
import { SetPicture } from './../app/Slice'
import { GetCookie } from '../Action/GetCookie'
import { AllApi } from './../Api/AllApi'
import { setUserCoin } from './../app/Home'
import { useHistory } from 'react-router-dom';

const BottomNavigation = ({ url, seturl }) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const Email = useSelector((state) => state.counter.email)
    const Picture = useSelector((state) => state.counter.Picture)

    const Data = useLiveQuery(
        () => db.Data.toArray()
    );
    const getProfile = async () => {
        const Token = GetCookie('Token');

        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetProfile(Token)
        // console.log(res)
        if (res.code == 200) {
            dispatch(setUserCoin(res.data.user.coins))
            db.Data.toArray(async function (data) {
            // console.log(data)
            if(data.length>0){
                let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email,PhoneNumber:res.data.user.phonenumber })

            }else if(data.length==0){
                let res1 = db.Data.add({ Email: res.data.user.email ,PhoneNumber:res.data.user.phonenumber})

            }
            });
            // if()
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }
    }
    useEffect(() => {
        // console.log(Data ? Data : null)
        if (Data) {
            if (Data.length > 0) {
                dispatch(SetEmail(Data[0].Email))
                dispatch(SetPicture(Data[0].Picture))
            } else {
                getProfile()
            }

        }
    }, [Data, Email])

    useEffect(() => {
        getProfile()
    }, [])

    const location = useLocation()
    // console.log(Picture)
    // console.log(Email)
    return (
        <nav className="nav">
            <Link to='/Home'
                className={location.pathname == '/Home' || location.pathname == undefined || location.pathname == '/ChannelsList' ? "nav__link nav__link--active" : 'nav__link nav__link'}

            >
                {
                    location.pathname == '/Home' ?
                        // <HomeIcon fontSize='medium' />
                        <> <div className='BottomNavigationActiveLink'>
                            <img src={Home} className='BottomNavigationLinkImg' />
                        </div>
                            <span className="nav__textActive">Home</span>
                        </>

                        : <> <div className='BottomNavigationDeActiveLink'>
                            <img src={HomeDisable} className='BottomNavigationLinkImg' />
                        </div>
                            <span className="nav__text">Home</span>
                        </>


                }


                {/* <span className="nav__textActive">Home</span> */}
            </Link>
            <Link to="/Coin?Status=Free"
                className={location.pathname == '/Coin' ? "nav__link nav__link--active" : 'nav__link nav__link'}

            >
                {location.pathname == '/Coin' ?
                    <> <div className='BottomNavigationActiveLink'>
                        <img src={CoinActive} className='BottomNavigationLinkImg' />
                    </div>
                        <span className="nav__textActive">Coin</span>
                    </>


                    :
                    <>
                        <div className='BottomNavigationDeActiveLink'>
                            <img src={Coin} className='BottomNavigationLinkImg' />
                        </div>
                        <span className="nav__text">Coin</span>

                    </>

                }




            </Link>
            <Link to='/Game' className={location.pathname == '/Game' ? "nav__link nav__link--active" : 'nav__link nav__link'}

            >
                {location.pathname == '/Game' ?
                    <> <div className='BottomNavigationActiveLink'>
                        <img src={GameActiveIcon} className='BottomNavigationLinkImg' style={{ "width": "24px", "height": "24px" }} />
                    </div>
                        <span className="nav__textActive">Game</span>
                    </>

                    // <img src={Receiptfilled} />
                    :
                    <>
                        <div className='BottomNavigationDeActiveLink'>
                            <img src={GameIcon} className='BottomNavigationLinkImg' style={{ "width": "24px", "height": "24px" }} />
                        </div> <span className="nav__text">Game</span>
                    </>

                    // <img src={Receipt} />
                }




            </Link>
            <Link to='/Orders' className={location.pathname == '/Orders' ? "nav__link nav__link--active" : 'nav__link nav__link'}

            >
                {location.pathname == '/Orders' ?
                    <> <div className='BottomNavigationActiveLink'>
                        <img src={Receiptfilled} className='BottomNavigationLinkImg' style={{ "width": "18px", "height": "20px" }} />
                    </div>
                        <span className="nav__textActive">Orders</span>
                    </>

                    // <img src={Receiptfilled} />
                    :
                    <>
                        <div className='BottomNavigationDeActiveLink'>
                            <img src={Receipt} className='BottomNavigationLinkImg' style={{ "width": "18px", "height": "20px" }} />
                        </div> <span className="nav__text">Orders</span>
                    </>

                    // <img src={Receipt} />
                }




            </Link>

            <Link to='/Profile' className={location.pathname == '/Profile' || location.pathname == 'LogOut' ? "nav__link nav__link--active" : 'nav__link nav__link'}
            >
                {location.pathname == '/Profile' ?
                    // <AccountCircleIcon fontSize='medium' /> 
                    <>
                        <div className='ProfilePhotoTextContainer BottomNavigationActiveLink'>
                            {
                                Picture ?
                                    <img src={Picture} className='ProfilePhotoImg' />
                                    :
                                    <span className='ProfilePhotoText'>
                                        {Email.charAt(0).toUpperCase()}
                                    </span>

                            }

                        </div>
                        <span className="nav__textActive">Profile</span>
                    </>

                    :
                    <>
                        <div className='ProfilePhotoTextContainer'>
                            {
                                Picture ?
                                    <img src={Picture} className='ProfilePhotoImg' />
                                    :
                                    <span className='ProfilePhotoText'>
                                        {Email ? Email.charAt(0).toUpperCase() : <img src={Picture} className='ProfilePhotoImg' />}
                                    </span>
                            }

                        </div>
                        <span className="nav__text">Profile</span>
                    </>

                }



            </Link>


        </nav>
    )
}

export default BottomNavigation