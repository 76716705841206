import React from 'react'
import Back from './../../Public/Image/BuyCoin/Back.svg'
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { setMiniLoading } from './../../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import './SetName.css'
import { useState, useEffect } from 'react';
import Loading from './../../Loading'
import { AllApi } from './../../Api/AllApi'
import Snackbar from '@mui/material/Snackbar';
import { GetCookie } from './../../Action/GetCookie'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useHistory } from 'react-router-dom';
import { OnlineRequestLeagueListGame } from '../../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../../GameAction/OnlineRequestTopJoinerGame'
const SetName = () => {
  const MiniLoading = useSelector((state) => state.Home.MiniLoading)
  const dispatch = useDispatch()
  const [LoadState, setLoadState] = useState(false)

  const [Name, setName] = useState('')
  const [EmptyName, setEmptyName] = useState(false)
  const [ErrorLength, setErrorLength] = useState(false)
  const [state, setState] = React.useState({
    opens: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, opens } = state;
  const handleClick = (newState) => {
    setState({ ...newState, opens: true });
  };
  const handleClose = () => {
    setState({ ...state, opens: false });
  };
  useEffect(() => {
    setLoadState(true)
    GetProfile()
    setTimeout(() => {
      setLoadState(false)

    }, 1000);
  }, [])
  const Token = GetCookie('Token');
  const [PrevName, setPrevName] = useState('')
  const GetProfile = async () => {
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetProfile(Token)

    if (res.code == 200) {
      console.log(res)
      setName(res.data.user.nameAccount)
    }

  }
  const HandleNameChange = (name) => {
    let result = name.trim();

    setEmptyName(false)
    setErrorLength(false)
    setName(result)
 
    setNotAllow(false)
    setAlreadyTaken(false)
    setDayLimit(false)

  }
  const history = useHistory();
const [NotAllow, setNotAllow] = useState(false)
const [AlreadyTaken, setAlreadyTaken] = useState(false)
const [DayLimit, setDayLimit] = useState(false)

  const SaveName = async () => {
    if (Name.length > 0 && Name.length < 25) {

      setEmptyName(false)
      setErrorLength(false)
      setNotAllow(false)
      setAlreadyTaken(false)
      setDayLimit(false)
      const MakeApiClass = new AllApi();
      let SetNameAccountResponse = await MakeApiClass.SetNameAccount(Name)
      console.log(SetNameAccountResponse)
      if (SetNameAccountResponse.code == 200) {
        handleClick({ vertical: 'bottom', horizontal: 'center' })
        const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
        const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
        let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
        let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
        setTimeout(() => {
          history.push("/EditProfile");
        }, 2000);
      } else if (SetNameAccountResponse.code == 300) {
        // اگر nameAccount   از body نیاد یا بیشتر از ۲۴ کاراکتر بیاد 
        setErrorLength(true)

      }
      else if (SetNameAccountResponse.code == 301) {
        // اگر اسم که وارد کرده قبلا توسط خودش یا شخص دیگری اشغال شده باشد ریسپانس:
        setAlreadyTaken(true)

      }

      else if (SetNameAccountResponse.code == 302) {
        // اگراز کلمات نامناسب استفاده کند ریسپانس:
        setNotAllow(true)

      }
      else if (SetNameAccountResponse.code == 303) {
        // اگر از اخرین تاریخی که اسم رو عوض کرده 10 روز نگذشته باشه ریسپانس:
        setDayLimit(true)

      }
   

    } else if (Name.length == 0) {

      setEmptyName(true)

    } else {
      setErrorLength(true)

    }

  }

  return (
    LoadState ?
      <Loading />
      :
      <div className='SetNameContainer'>
        <div className='SetNameHeader'>
          <Link to="/EditProfile" className='SetNameBackIcon'>
            <img src={Back} className='SetNameBackIconImg' />
          </Link>
          <div className='SetNameHeaderText'>
            Name
          </div>
        </div>
        <div className='SetNameBody'>


          <div className='SetNameBodyText'>
            You can change your name
          </div>
          <div className='SetNameInput'>
            {
              EmptyName ?
                <TextField
                  error
                  id="outlined-error-helper-text"
                  label="Name"
                  value={Name}
                  onChange={(e) => HandleNameChange(e.target.value)}
                  helperText="Name  is empty"
                  className='RegisterEmailTextField'
                />
                :
                ErrorLength ?
                  <TextField
                    error
                    id="outlined-error-helper-text"
                    label="Name"
                    value={Name}
                    onChange={(e) => HandleNameChange(e.target.value)}
                    helperText="The maximum number of characters is 24"
                    className='RegisterEmailTextField'
                  />


                  :
                  NotAllow?
                  <TextField
                    error
                    id="outlined-error-helper-text"
                    label="Name"
                    value={Name}
                    onChange={(e) => HandleNameChange(e.target.value)}
                    helperText="This name is not allowed"
                    className='RegisterEmailTextField'
                  />
                  :
                  AlreadyTaken?
                  <TextField
                    error
                    id="outlined-error-helper-text"
                    label="Name"
                    value={Name}
                    onChange={(e) => HandleNameChange(e.target.value)}
                    helperText="This name is already taken, Please choose another name"
                    className='RegisterEmailTextField'
                  />
                  :
                  DayLimit?
                  <TextField
                    error
                    id="outlined-error-helper-text"
                    label="Name"
                    value={Name}
                    onChange={(e) => HandleNameChange(e.target.value)}
                    helperText="You'll have to wait a few days to change your name"
                    className='RegisterEmailTextField'
                  />
                  :
                  <TextField id="outlined-basic" label="Name" value={Name}
                    onChange={(e) => HandleNameChange(e.target.value)}
                    variant="outlined" className='RegisterEmailTextField' />
            }






          </div>



        </div>
        <div className='SetNameFooter'>
          <div className='SetNameLinkDiv'>
            <button className="SetNameLink"
              onClick={() => SaveName()}
            >
              {MiniLoading ?
                <div className="loadminijoin"></div>
                :
                'Save'
              }
            </button>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={opens}
          onClose={handleClose}
          // message="Name has been saved"
          key={vertical + horizontal}
          autoHideDuration={1000}
          message={<span id="client-snackbar"><VerifiedIcon className='CheckIcon' /> Name has been saved</span>}
        />
      </div>


  )
}

export default SetName