import React, { useEffect, useState, useRef } from 'react'
import './Game.css'
import DoneIcon from '@mui/icons-material/Done';

import BottomNavigation from './../Home/BottomNavigation'
import MyLeagueComponent from './MyLeague/MyLeagueComponent';
import TopJoinerComponent from './TopJoiner/TopJoinerComponent';
import HeaderProgressBar from './HeaderProgressBar'
import GetName from './BottomSheets/GetName'
import { useSelector, useDispatch } from 'react-redux'


import { AllApi } from '../Api/AllApi'

import NoInternet1 from './../Public/Image/Join/NoInternet.svg'
import { CheckDataBaseGame } from './../GameAction/CheckDataBaseGame'

import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetIsBetweenLeaguge
} from './../app/Game'


import { GameDB } from './../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';

import Loading from './../Loading'
import { OnlineRequestAppSettingGame } from '../GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from '../GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from '../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../GameAction/OnlineRequestTopJoinerGame'

const Game = () => {
    const dispatch = useDispatch()
    const [MyLeague, setMyLeague] = useState(true)
    const GameLoading = useSelector((state) => state.Game.GameLoading)

    
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
    const IsBetweenLeaguge = useSelector((state) => state.Game.IsBetweenLeaguge)

    const [LoadState, setLoadState] = useState(false)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [NoInternet, setNoInternet] = useState(false)
    // const [IsBetweenLeaguge, setIsBetweenLeaguge] = useState(null)
    const [runInterval, setRunInterval] = useState(false);
    const [LastTime, setLastTime] = useState(0);
    const [CashTime, setCashTime] = useState(0);
    const intervalRef = useRef(null);
    // SnackBar /////////////////////////
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const [open, setOpen] = useState(false)

    function onDismiss() {
        setTimeout(() => {
            setOpen(false)
        }, 300);
    }
    // // SnackBar//////////////////////////////


    useEffect(() => {
        // console.log(window.navigator.onLine)
        if (NoInternet == true) {

            const timer = setInterval(async () => {
                let IsOnline = window.navigator.onLine
                // console.log(IsOnline)
                if (IsOnline == true) {
                    clearInterval(timer);
                    // StartGame()

                }
            }, 10000);
            // console.log(NoInternet)
        }
    }, [NoInternet])


    useEffect(() => {

        if (runInterval) {
            intervalRef.current = setInterval(() => {
                let NowInNow = new Date().getTime();
                let LastTimeLeague = localStorage.getItem('LastTimeLeague')
                let Distance = LastTimeLeague - NowInNow;
                // var minutescashTime = Math.floor(CashTime / 60);
                var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
                // console.log(minutes, seconds)

                if (minutes < 0) {
                    setLastUpdate(Math.abs(minutes))
                } else {
                    setLastUpdate(false)

                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current);
        }; // clear interval when unmounting the component
    }, [runInterval, LastTime, CashTime]);



    // const GetOnlineRequest = async () => {
    //     const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
    //     const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
    //     const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();

    //     let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList()
    //     await OnlineRequestProfileGameClass.OnlineRequestProfile()
    //     await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
    //     // console.log(res)
    //     OfflineRequestAppSetting()
    //     OfflineRequestProfile()
    //     OfflineRequestLeagueList()
    //     dispatch(SetClaimRewardCall(false))
    //     console.log('first')
    // }
    // useEffect(() => {

    //     // console.log(ClaimRewardCall)
    //     if (ClaimRewardCall == true) {
    //         GetOnlineRequest()
    //     }

    //     if (LastUpdate >= 1) {
    //         StartGame()
    //     }
    // }, [ClaimRewardCall])

    // const OfflineRequestAppSetting = async () => {
    //     // console.log('OfflineRequestAppSetting')
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     if (AppSetting.length > 0) {
    //         // console.log(AppSetting[0])
    //         // console.log(AppSetting[0].AppSetting.leagues)


    //     } else {
    //         // console.log('error app')
    //         // setNoInternet(true)

    //         const timer = setInterval(async () => {
    //             AppSetting = await GameDB.AppSetting.toArray()
    //             // console.log(AppSetting)
    //             if (AppSetting.length == 1) {
    //                 clearInterval(timer);
    //                 OfflineRequestAppSetting()
    //             } else {
    //                 // console.log('error again !!!')
    //             }

    //         }, 1000);
    //     }


    // }
    // const OfflineRequestProfile = async () => {
    //     // console.log('OfflineRequestProfile')
    //     let Profile = await GameDB.Profile.toArray()
    //     // console.log(Profile)
    //     // console.log(Profile.length)
    //     if (Profile.length > 0) {
    //         // console.log(Profile[0])
    //         // dispatch(SetNameAccount(Profile[0].Profile.nameAccount))
    //         dispatch(SetJoinCountRedux(Profile[0].Profile.joinCount))

    //     } else {
    //         // console.log('error')
    //         // setNoInternet(true)
    //         const timer = setInterval(async () => {
    //             Profile = await GameDB.Profile.toArray()
    //             // console.log(AppSetting)
    //             if (Profile.length == 1) {
    //                 clearInterval(timer);
    //                 OfflineRequestProfile()
    //             } else {
    //                 // console.log('error again !!!')
    //             }

    //         }, 1000);
    //     }

    // }

    const OfflineRequestLeagueList = async () => {
        // console.log('OfflineRequestLeagueList')

        let LeagueList = await GameDB.LeagueList.toArray()
        if (LeagueList.length > 0) {
            // console.log(LeagueList[0])
            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
            dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
            let LevelUser = false
            LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

            // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)


            let cacheTime = LeagueList[0].LeagueList.data.cacheTime
            let LastTime = LeagueList[0].LastTime
            clearInterval(intervalRef.current);
            localStorage.setItem('LastTimeLeague', LastTime)
            setLastTime(LastTime)
            setCashTime(cacheTime)
            setRunInterval(true)
        } else {
            // console.log('error')
            // setNoInternet(true)
            const timer = setInterval(async () => {
                LeagueList = await GameDB.LeagueList.toArray()
                // console.log(LeagueList)
                if (LeagueList.length == 1) {
                    clearInterval(timer);
                    OfflineRequestLeagueList()
                    // setNoInternet(false)
                } else {
                    // console.log('error again !!!')
                }

            }, 1000);

        }

    }
    // const GetUserDataFromAppSetting = async (LevelUser) => {
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     // console.log(AppSetting)
    //     if (AppSetting.length == 1) {
    //         // console.log(1)
    //         // console.log(AppSetting[0].AppSetting)
    //         dispatch(SetAppSettingData(AppSetting[0].AppSetting))
    //         let RewardArray = AppSetting[0].AppSetting.leagues

    //         if (LevelUser != false) {
    //             RewardArray.map(item => {

    //                 if (item.leagueLevel == LevelUser) {

    //                     dispatch(SetRewardLeague(item))
    //                 }
    //             })
    //             dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

    //             let LeaguesArray = AppSetting[0].AppSetting.leagues
    //             LeaguesArray.map(item => {
    //                 if (item.leagueLevel == LevelUser) {
    //                     dispatch(SetMyLeagueRank(item.leagueName))
    //                     dispatch(SetLeagueLimitJoinProgress(item.max))
    //                     dispatch(SetLeagueMinimumJoinProgress(item.min))

    //                     dispatch(SetMyLeagueRankNumber(item.leagueLevel))

    //                 }
    //             })
    //         }
    //     } else {
    //         // console.log(2)
    //         const timer = setInterval(async () => {
    //             AppSetting = await GameDB.AppSetting.toArray()
    //             // console.log(AppSetting)
    //             if (AppSetting.length == 1) {
    //                 clearInterval(timer);
    //                 GetUserDataFromAppSetting(LevelUser)
    //             } else {
    //                 // console.log(147852)
    //             }

    //         }, 1000);

    //     }


    // }
    // const GetleagueListJoinsfunc = async () => {
    //     let Data;
    //     const MakeApiClass = new AllApi();
    //     let Result = await MakeApiClass.GetleagueListJoins()
    //     // console.log(Result)

    //     if (Result.code == 200) {
    //         Data = Result

    //     } else if (Result.code == 301) {
    //         Data = 'Name'

    //     } else if (Result.code == 999) {
    //         Data = 'Network'
    //     }
    //     return Data
    // }



    // const StartGame = async () => {
    //     const CheckDataBaseGameClass = new CheckDataBaseGame();
    //     await CheckDataBaseGameClass.CheckDataBase()
    // }



    useEffect(() => {
        // console.log(IsBetweenLeaguge) 
        // if (IsBetweenLeaguge == true ) {
            // dispatch(SetIsBetweenLeaguge(false))

            // setTimeout(() => {

                // OfflineRequestAppSetting()
                // OfflineRequestProfile()
                OfflineRequestLeagueList()
                // window.location.reload();
            // }, 10);


        // }



        return () => {

        }
    }, [IsBetweenLeaguge])



   useEffect(() => {
    // console.log(LoadState)
    // console.log(IsBetweenLeaguge)
    // console.log(UserLeagueData)
    // console.log(MyLeague)
    // console.log(MyLeagueRank)
    // console.log(LastUpdate)
    // console.log(TopJoinerTime)
    // console.log(NoInternet)
    // console.log(LoadState)
    }, [IsBetweenLeaguge,UserLeagueData])
    return (
        <>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                onDismiss={onDismiss}
                message="Something went wrong"
                key={vertical + horizontal}
                autoHideDuration={3000}
            />
            {
                !GameLoading ?
                    NoInternet ?
                        <>
                            <div className='JoinContainer'>
                                <div className='NoInterNetLogo'>
                                    <img src={NoInternet1} />
                                </div>
                                <div className='NoInterNetText'>
                                    Please make sure your device is connected to the internet
                                </div>
                                <div className='NoInterNetButton'>
                                    <button onClick={() => window.location.reload()}>
                                        Retry
                                    </button>
                                </div>

                            </div>
                        </>

                        :
                        IsBetweenLeaguge != null ?
                            UserLeagueData.topUsers ?
                                <div className='Game'>
                                    <div className='GameHeader'>
                                        <div className='GameHeaderInner'>
                                            <div>
                                                Game
                                            </div>
                                            <div className='GameHeaderProgress' >
                                                <HeaderProgressBar />
                                            </div>

                                        </div>

                                    </div>

                                    <div className='GameContent'>

                                        {
                                            // !IsBetweenLeaguge ?
                                            <div className='GameChips'>
                                                <div className={MyLeague ? 'GameChipsItemLeft noSelect ActiveChips' : 'GameChipsItemLeft noSelect'} onClick={() => setMyLeague(true)}>
                                                    {MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}
                                                    My League
                                                </div>
                                                <div className={!MyLeague ? 'GameChipsItemRight noSelect ActiveChips' : 'GameChipsItemRight noSelect'} onClick={() => setMyLeague(false)}>
                                                    {!MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}

                                                    Top Joiners
                                                </div>
                                            </div>
                                            // : null
                                        }


                                        <div className='SelectComponent'>
                                            {

                                                MyLeague ?

                                                    <MyLeagueComponent MyLeagueRank={MyLeagueRank} LastUpdate={LastUpdate} />
                                                    :
                                                    <TopJoinerComponent IsBetweenLeaguge={IsBetweenLeaguge} Time={TopJoinerTime} LastUpdate={LastUpdate} />
                                            }




                                        </div>
                                        <GetName />
                                    </div>
                                    <div className='GameFooter'>

                                        <BottomNavigation />

                                    </div>
                                </div>

                                : <Loading />
                            : <Loading />
                    : <Loading />

            }

        </>
    )
}


export default Game