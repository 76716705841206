import React from 'react';
import './App.css';
import Auth from './Auth/Auth'
import Login from './Auth/Login'
import Register from './Auth/Register'
import RegisterGetPassword from './Auth/RegisterGetPassword'
import Home from './Home/Home'
import GetPhoneNumber from './Auth/GetPhoneNumber'
import CheckVerificationCode from './Auth/CheckVerificationCode'
import ForgetPassword from './Auth/ForgetPassword'
import ForgetPasswordGetCode from './Auth/ForgetPasswordGetCode'
import ChangePassword from './Auth/ChangePassword';
import Coin from './Coin/Coin'
import GetLinkUsername from './Home/GetLinkUsername'
import GetUsernameView from './Home/GetUsernameView'
import Notice from './Home/Notice'
import TransferHistory from './Coin/TransferHistory';
import GiftCode from './Coin/GiftCode'
import Profile from './Profile/Profile'
import AccountInformation from './Profile/AccountInformation'
import Payments from './Profile/Payments'
import LogOut from './Profile/LogOut'
import DeleteAccount from './Profile/DeleteAccount'
import AddNumber from './Profile/AddNumber'
import AddNumberVerify from './Profile/AddNumberVerify'
import EditNumber from './Profile/EditNumber'
import EditNumberVerify from './Profile/EditNumberVerify'
import Privacy from './Profile/Privacy'
import SetPassword from './Profile/SetPassword'
import GetCheckPassword from './Profile/GetCheckPassword'
import GetChangePassword from './Profile/GetChangePassword'
import Orders from './MyOrder/Orders'
import OrderDetailsSingle from './MyOrder/OrderDetailsSingle'
import OrderDetailsAll from './MyOrder/OrderDetailsAll'
import Game from './Game/Game'
import AllReward from './Game/AllReward/AllReward'

import SellingPhoneNumbers from './Sale/SellingPhoneNumbers'
import SaleHistory from './Sale/SaleHistory'
import VerificationCode from './Sale/VerificationCode'
import EditProfile from './Profile/EditProfile';
import SetName from './Profile/SetName/SetName';
import "./Public/fonts/font.css"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginTelegram from './TelegramLogin/LoginTelegram'
import VerifyCode from './TelegramLogin/VerifyCode'
import JoinBot from './TelegramLogin/JoinBot'
import AutoManualJoin from './Coin/AutoManualJoin';
import Update from './BottomSheet/Update'
import { useState, useRef } from 'react';
import { useEffect } from 'react';

import SnackBarEndLeague from './Game/GlobalGameComponent/SnackBarEndLeague'
import { CheckDataBaseGame } from './GameAction/CheckDataBaseGame'
import { useSelector, useDispatch } from 'react-redux'
import { GetCookie } from './Action/GetCookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Congratulations from './Game/BottomSheets/Congratulations'
import Congratulations2 from './Game/BottomSheets/Congratulations2'
import Congratulations3 from './Game/BottomSheets/Congratulations3'
import Promotion from './Game/BottomSheets/Promotion'
import { GameDB } from './DataBase/GameDB'

import { OnlineRequestAppSettingGame } from './GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from './GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from './GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from './GameAction/OnlineRequestTopJoinerGame'


import { GetUserDataFromAppSettingGame } from './GameAction/GetUserDataFromAppSettingGame'
import {
  SetAppSettingData,
  SetUserLeagueData,
  SetMyLeagueRank,
  SetLeagueLimitJoinProgress,
  SetLeagueMinimumJoinProgress,
  SetRewardLeague,
  SetMyLeagueRankNumber,
  SetGiftTopjoin,
  SetNameAccount,
  SetJoinCountRedux,
  SetPromotionBottomSheet,
  SetUserReward,
  SetClaimRewardCall,
  SetDataClaimReward,
  SetXpLimit,
  SetXpCount,
  SetLevelUserHeaderProgress,
  SetSnackBarEndLeagueState
} from './app/Game'

function App() {





  const dispatch = useDispatch()

  const [Mode, setMode] = useState('light')
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    if (mediaQuery.matches) {
      setMode('dark')
    }
  }, [])

  let AuthState = GetCookie('Token')
  const history = useHistory();

  useEffect(() => {
    if (AuthState == null || AuthState == 'null') {
      // history.push(`/`);
    }
  }, [AuthState])


  // useEffect(() => {
  //   // StartGame()
  //   // StartTimer()

  // }, [AuthState])
  // const StartGame = async () => {
  //   if (AuthState == null || AuthState == 'null') {
  //   } else {
  //     const CheckDataBaseGameClass = new CheckDataBaseGame();
  //     await CheckDataBaseGameClass.CheckDataBase(dispatch)
  //   }
  // }





  // const intervalJoin = useRef(true);
  // useEffect(() => {

  //   if (intervalJoin.current != null) {
  //     intervalJoin.current = setInterval(() => {
  //       CheckClaim()
  //       clearInterval(intervalJoin.current);
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(intervalJoin.current);
  //   };

  // }, [JoinCountRedux])
  // const CheckClaim = async () => {
  //   const CheckDataBaseGameClass = new CheckDataBaseGame();
  //   await CheckDataBaseGameClass.CheckClaimReward(dispatch, JoinCountRedux)
  // }





  // const StartTimer = async () => {
  //   const CheckDataBaseGameClass = new CheckDataBaseGame();
  //   let timerresponse = await CheckDataBaseGameClass.TimerLeague(dispatch) 

  //   return timerresponse
  // }
  // const intervalRef1 = useRef(true);
  // useEffect(() => {
  //   if (intervalRef1.current != null) {
  //     intervalRef1.current = setInterval(async () => {
  //       let res = await StartTimer()
  //     }, 1000);
  //   }

  //   // return () => {
  //   //   clearInterval(intervalRef1.current);
  //   // };
  // }, [intervalRef1]);




  const CheckDataBaseFunc = async () => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    let CheckDataBaseRes = await CheckDataBaseGameClass.CheckDataBase(dispatch)
    return CheckDataBaseRes
  }


  const StartLoop = async (intervalRef1) => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    let StartLoopRes = await CheckDataBaseGameClass.LoopOfGame(dispatch, intervalRef1)
    return StartLoopRes
  }
  const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)

  const intervalRef2 = useRef(true);

  const AppSettingData = useSelector((state) => state.Game.AppSettingData)
  const ProfileData = useSelector((state) => state.Game.ProfileData)
  const TopJoinerData = useSelector((state) => state.Game.TopJoinerData)
  const PrevLeagueData = useSelector((state) => state.Game.PrevLeagueData)
  const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
  const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
  const PromotionBottomSheet = useSelector((state) => state.Game.PromotionBottomSheet)


  useEffect(() => {

    if (ClaimRewardCall == true) {
      dispatch(SetClaimRewardCall(false))

      RequestForDataAfterClaim()
    }
    return () => {

    }
  }, [ClaimRewardCall])
  const RequestForDataAfterClaim = async () => {

    const CheckDataBaseGameClass = new CheckDataBaseGame();
    await CheckDataBaseGameClass.CollectDataAfterClaim(dispatch)


  }
  const calcJoinAndLevel = async (LevelUser) => {
    // let PreviousLeague = await GameDB.PreviousLeague.toArray()

    const GetUserDataFromAppSettingClass = new GetUserDataFromAppSettingGame();
    let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)
  }
  const CompareJoinAndLeagueLevelFunction = async (LevelUser, JoinCountRedux) => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    let StartLoopRes = await CheckDataBaseGameClass.CompareJoinAndLeagueLevel(dispatch, LevelUser, JoinCountRedux)
  }
  useEffect(() => {

    let LevelUser = false
    if (UserLeagueData.userInformation) {
      LevelUser = UserLeagueData.userInformation.leagueLevel

      calcJoinAndLevel(LevelUser)

    }



  }, [UserLeagueData])
  useEffect(() => {
    CompareJoinAndLeagueLevelFunction(JoinCountRedux)
    // CheckDataBaseFunc()
    return () => {

    }
  }, [JoinCountRedux])

  async function waitFor10Seconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('Waiting period of 10 seconds completed.');
      }, 10000);
    });
  }

  const [reswait, setreswait] = useState('light')
  const intervalRef1 = useRef(true);
  useEffect(() => {

    CheckDataBaseFunc()


    intervalRef1.current = setInterval(async () => {
      let res = await StartLoop(intervalRef1)

      if (res == false) {
        clearInterval(intervalRef1.current);
        let wait = await waitFor10Seconds();
        setreswait(wait)
        setTimeout(async () => {
          CheckDataBaseFunc()
          window.location.reload();
        }, 5000);
      }
    }, 1000);



    // return () =>{
    //   console.log('clear timer') 
    //   clearInterval(intervalRef1.current);
    // };
  }, [reswait, intervalRef1]);


  const GetTopJoinerCompareWithJoin = async (dispatch) => {
    let TopJoiner = await GameDB.TopJoiner.toArray()
    const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
    const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
    if (TopJoiner[0]) {

      TopJoiner[0].TopJoiner.map(async (item) => {
        if (item.email == TopJoiner[0].userInformation.email) {
          if (TopJoiner[0].userInformation.joinCount == JoinCountRedux) {

            let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
            let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
          } else {


            let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
            let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)

          }

        }
      })

    }

  }
  useEffect(() => {
    GetTopJoinerCompareWithJoin(dispatch)
  }, [JoinCountRedux]);

  return (
    <div className={"App " + Mode} >
      <Promotion />
      <Congratulations />
      <Congratulations2 />
      <Congratulations3 />
      <Router>
        <Switch>

          <Route exact path="/" component={Auth} />
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/RegisterGetPassword" component={RegisterGetPassword} />
          <Route path="/Home" component={Home} />
          <Route path="/GetPhoneNumber" component={GetPhoneNumber} />
          <Route path="/CheckVerificationCode" component={CheckVerificationCode} />
          <Route path="/ForgetPassword" component={ForgetPassword} />
          <Route path="/ForgetPasswordGetCode" component={ForgetPasswordGetCode} />
          <Route path="/ChangePassword" component={ChangePassword} />
          <Route path="/Coin" component={Coin} />
          <Route path="/LoginTelegram" component={LoginTelegram} />
          <Route path="/VerifyCode" component={VerifyCode} />
          <Route path="/JoinBot" component={JoinBot} />
          <Route path="/AutoManualJoin" component={AutoManualJoin} />
          <Route path="/Notice" component={Notice} />
          <Route path="/GetLinkUsername" component={GetLinkUsername} />
          <Route path="/GetUsernameView" component={GetUsernameView} />

          <Route path="/TransferHistory" component={TransferHistory} />
          <Route path="/GiftCode" component={GiftCode} />
          <Route path="/Profile" component={Profile} />
          <Route path="/AccountInformation" component={AccountInformation} />
          <Route path="/Payments" component={Payments} />
          <Route path="/LogOut" component={LogOut} />
          <Route path="/DeleteAccount" component={DeleteAccount} />
          <Route path="/AddNumber" component={AddNumber} />
          <Route path="/AddNumberVerify" component={AddNumberVerify} />
          <Route path="/EditNumber" component={EditNumber} />
          <Route path="/EditNumberVerify" component={EditNumberVerify} />
          <Route path="/EditProfile" component={EditProfile} />
          <Route path="/SetName" component={SetName} />


          <Route path="/Orders" component={Orders} />
          <Route path="/OrderDetailsSingle" component={OrderDetailsSingle} />
          <Route path="/OrderDetailsAll" component={OrderDetailsAll} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/SetPassword" component={SetPassword} />
          <Route path="/GetCheckPassword" component={GetCheckPassword} />
          <Route path="/GetChangePassword" component={GetChangePassword} />
          <Route path="/Game" component={Game} />
          <Route path="/AllReward" component={AllReward} />


          <Route path="/SellingPhoneNumbers" component={SellingPhoneNumbers} />
          <Route path="/SaleHistory" component={SaleHistory} />
          <Route path="/VerificationCode" component={VerificationCode} />


        </Switch>
      </Router>
      <Update />
      <SnackBarEndLeague />
    </div>
  );
}

export default App;
