import React from 'react'
import { API_ID, API_HASH } from './../Const'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api, errors } from 'telegram'
import { TelegramDB } from './../DataBase/TelegramDB'
import { GetCookie } from './../Action/GetCookie'

import { AllApi } from './../Api/AllApi'



export async function ManualJoin(Account, MyClient, ID) {
    // console.log(Account, MyClient, ID)


    let Token = GetCookie('Token');
    let TgChID = Account.ListForJoin[0].tgChannelId ? Account.ListForJoin[0].tgChannelId : null
    // console.log(Account.ListForJoin[0])
    let UserName = Account.ListForJoin[0].username
    let BotSeS = Account.BotSession
    let Id = Account.ListForJoin[0].Id
    let _id = Account.ListForJoin[0]._id
    class AutoManualJoin {
        constructor(MySession, List, Username) {
            this.MySession = MySession;
            this.List = List;
            this.Username = Username
        }
        async Join() {
            try {
                const result = await MyClient.invoke(
                    new Api.channels.JoinChannel({
                        channel: this.List[0].username,
                        // channel: 'GB_PRODAZHA',
                        // https://t.me/GB_PRODAZHA
                    })
                );
                // console.log(result)
                return result;
            } catch (error) {
                return error
            }

        }
        async IsMember() {
            let IsMemberUser;
            try {
                // console.log(this.Username)
                if (this.Username === null) {
                    IsMemberUser = 'username null'
                    const IsMember = await MyClient.invoke(
                        new Api.channels.GetParticipant({
                            channel: this.List[0].username,
                            participant: Account.User.id.toString(),
                        })
                    );
                    IsMemberUser = true
                } else {
                    const IsMember = await MyClient.invoke(
                        new Api.channels.GetParticipant({
                            channel: this.List[0].username,
                            participant: this.Username,
                        })
                    );
                    IsMemberUser = true
                }

            } catch (error) {
                // console.log(error)
                // console.log(error.errorMessage)
                IsMemberUser = false

            }
            return IsMemberUser

        }
        async Next() {
            let NewList = this.List.filter((item, i) => {
                if (i != 0) {
                    return item
                }
            })
            try {
                let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ ListForJoin: NewList })
            } catch (error) {
                // console.log(error)

            }
        }

    }

    const MyAccount = new AutoManualJoin(Account.Session, Account.ListForJoin, Account.User.username);

    let IsMember = await MyAccount.IsMember()

    if (IsMember === false) {

        let res = await MyAccount.Join()
        // console.log(res)
        // res = { errorMessage: 'FLOOD', seconds: 100 }
        if (res.chats) {
            // let JoinResponse = await JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)
            const MakeApiClass = new AllApi();
            let JoinResponse = await MakeApiClass.JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)
            console.log(JoinResponse)
            if (JoinResponse.code == 200) {
                let Next = await MyAccount.Next()

            } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                // history.push("/");


            }


            // dispatch(SetJoinCountRedux(JoinResponse.data.user.joinCount)) 
            return { status: 'ok', res: JoinResponse }
        } else if (res.errorMessage == 'FLOOD') {
            // console.log(res.errorMessage)
            // console.log(res.stack)
            // console.log(res.seconds)
            let LimitTime = res.seconds * 1000
            let FinalLimit = Date.now() + LimitTime
            try {
                let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
            } catch (error) {
                // console.log(error)
            }
            return res.errorMessage
        } else if (res.errorMessage == "CHANNELS_TOO_MUCH") {
            return "CHANNELS_TOO_MUCH"
        }
    } else if (IsMember == true) {
        let Next = await MyAccount.Next()
        return 'already join'
    } else if (IsMember == 'username null') {
        return 'username null'

    }

}